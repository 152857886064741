<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Sliders</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Slider
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <a @click="createOrUpdateSlider()" class="btn btn-primary font-weight-bolder font-size-sm">
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Create New
                  </a>&nbsp;
<!--                  <a @click="sliderType()" class="btn btn-outline-info font-weight-bolder font-size-sm">
                    Slider Type
                  </a>&nbsp;-->
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-col cols="12" sm="6" md="3">
                <v-text-field label="Title" v-model="search.title" outlined dense></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-select :items="status" v-model="search.status" label="Status" item-text="name" item-value="value" outlined dense ></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-select :items="visibility" v-model="search.visibility" label="Visibility" item-text="name" item-value="value" outlined dense ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <button class="btn btn-primary" @click="searchSlider">Search</button>
              </v-col>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" :class="sortedClass('title')"  @click="sortBy('title')">Title</th>
                  <th class="px-3">Image</th>

                  <th class="px-3">Visible</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr   v-for="item in sortedItems">
                    <td class="px-2">
                      <a @click="createOrUpdateSlider(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1"
                      >{{item.title}}</a>
                    </td>
                    <td class="px-3">
                      <v-img
                          :lazy-src="item.image_path "
                          aspect-ratio="1.7"
                          contain
                          max-height="70"
                          max-width="400"
                          :src="item.image_path "
                      ></v-img>
                    </td>
                    <td class="px-2">
                      <span class="badge" v-bind:class="{ 'badge-success': item.visibilty_text=='Visible', 'badge-danger': item.visibilty_text=='Not Visible' }">
                        {{ item.visibilty_text }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="badge" v-bind:class="{ 'badge-success': item.status_text=='Active', 'badge-danger': item.status_text=='Inactive' }">
                        {{ item.status_text }}
                      </span>
                    </td>

                    <td class="pr-0 px-2">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="createOrUpdateSlider(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click.prevent="deleteSlider(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>

                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSliders"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>
<script>
import BannerService from "@/services/cms/banner/BannerService";
const banner=new BannerService();

export default {
  data() {
    return{
      dialog:false,
      total: null,
      perPage: null,
      page: null,
      sliders:[],
       types: [
      { text: 'Natural',value:'natural'},
      { text: 'Celebrity',value:'celebrity'},
      { text: 'Animal',value:'animal' },
      { text: 'Sports',value:'sports' },
      { text: 'Music',value:'music' },
      { text: 'Space',value:'space' },
    ],
      slider:{
        title:'',
        type:'',
        heading_text:'',
        subheading_text:'',
        image:null,
        button_status:false,
        button_target:false,
        button_text:'',
        button_link:'',
        secondary_button_status:false,
        secondary_button_target:false,
        secondary_button_text:'',
        secondary_button_link:'',
        position:'',
        description:'',
        status:'',
        visibility:''
      },
      status: [
        {name: 'Status', value: ''},
        {name: 'Active', value: 'active'},
        {name: 'Inactive', value: 'in_active'},
      ],
      visibility: [
        {name: 'Visibility', value: ''},
        {name: 'Visible', value: 'visible'},
        {name: 'Not Visible', value: 'not_visible'},
      ],
      search:{
        title:'',
        status:'',
      },
      sort: {
        key: '',
        isAsc: false
      },
    }
  },
  methods: {

    createOrUpdateSlider(sliderId){
      if(sliderId==null)
        this.$router.push({
          name:"sliders-create"
        });
      else
        this.$router.push({
          name:'sliders-edit',
          params:{sliderId:sliderId}
        })
    },
    sliderType(){
      this.$router.push({
        name: "slider-type",
      });
    },
    getAllSliders(){
      banner
          .paginate(this.search,this.page)
          .then(response => {
            this.sliders=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          });
    },
    sortedClass (key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
    },
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    searchSlider(){
      this.getAllSliders();
    },
    deleteSlider(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            banner
                .delete(item)
                .then((response) => {
                  this.getAllSliders();
                  this.$snotify.success("Slider Deleted Sucessfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });}
        },
      });
    },
  },
  mounted() {
    this.getAllSliders();
  },
  computed:{
    sortedItems () {
      const list = this.sliders.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    }
  },
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>